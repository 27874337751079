import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _563e54d4 = () => interopDefault(import('../pages/accessibility.vue' /* webpackChunkName: "pages/accessibility" */))
const _08440926 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _34812f83 = () => interopDefault(import('../pages/legionella/index.vue' /* webpackChunkName: "pages/legionella/index" */))
const _0a067801 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _3a635dfe = () => interopDefault(import('../pages/terms-of-use.vue' /* webpackChunkName: "pages/terms-of-use" */))
const _915d037c = () => interopDefault(import('../pages/thank-you.vue' /* webpackChunkName: "pages/thank-you" */))
const _b3b332c8 = () => interopDefault(import('../pages/legionella/downloads.vue' /* webpackChunkName: "pages/legionella/downloads" */))
const _40317bf2 = () => interopDefault(import('../pages/legionella/faq.vue' /* webpackChunkName: "pages/legionella/faq" */))
const _7d0329cb = () => interopDefault(import('../pages/legionella/troubleshooting.vue' /* webpackChunkName: "pages/legionella/troubleshooting" */))
const _51b2c31e = () => interopDefault(import('../pages/legionella/workflow.vue' /* webpackChunkName: "pages/legionella/workflow" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accessibility",
    component: _563e54d4,
    name: "accessibility___en"
  }, {
    path: "/contact",
    component: _08440926,
    name: "contact___en"
  }, {
    path: "/fr",
    component: _0547af50,
    name: "index___fr"
  }, {
    path: "/legionella",
    component: _34812f83,
    name: "legionella___en"
  }, {
    path: "/privacy-policy",
    component: _0a067801,
    name: "privacy-policy___en"
  }, {
    path: "/terms-of-use",
    component: _3a635dfe,
    name: "terms-of-use___en"
  }, {
    path: "/thank-you",
    component: _915d037c,
    name: "thank-you___en"
  }, {
    path: "/fr/accessibilite",
    component: _563e54d4,
    name: "accessibility___fr"
  }, {
    path: "/fr/conditions-d-utilisation",
    component: _3a635dfe,
    name: "terms-of-use___fr"
  }, {
    path: "/fr/contacts",
    component: _08440926,
    name: "contact___fr"
  }, {
    path: "/fr/legionella",
    component: _34812f83,
    name: "legionella___fr"
  }, {
    path: "/fr/merci",
    component: _915d037c,
    name: "thank-you___fr"
  }, {
    path: "/fr/politique-de-confidentialite",
    component: _0a067801,
    name: "privacy-policy___fr"
  }, {
    path: "/legionella/downloads",
    component: _b3b332c8,
    name: "legionella-downloads___en"
  }, {
    path: "/legionella/faq",
    component: _40317bf2,
    name: "legionella-faq___en"
  }, {
    path: "/legionella/troubleshooting",
    component: _7d0329cb,
    name: "legionella-troubleshooting___en"
  }, {
    path: "/legionella/workflow",
    component: _51b2c31e,
    name: "legionella-workflow___en"
  }, {
    path: "/fr/legionella/depannage",
    component: _7d0329cb,
    name: "legionella-troubleshooting___fr"
  }, {
    path: "/fr/legionella/faq",
    component: _40317bf2,
    name: "legionella-faq___fr"
  }, {
    path: "/fr/legionella/procedure-de-travail",
    component: _51b2c31e,
    name: "legionella-workflow___fr"
  }, {
    path: "/fr/legionella/telechargements",
    component: _b3b332c8,
    name: "legionella-downloads___fr"
  }, {
    path: "/",
    component: _0547af50,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
